<template>
  <div>
    <div class="flex items-center gap-3 pt-4">
      <div>
        <div
          class="
            h-10
            w-10
            rounded-full
            bg-bg-alternate-14
            justify-center
            items-center
            flex
          "
        >
          <PlaceIcon class="h-6 w-6 text-text-primary" />
        </div>
      </div>

      <p class="text-sm text-text-alternate-1 font-medium">
        {{ props.fundraiser.contactAddress }}
      </p>
    </div>

    <div class="flex items-center gap-3 pt-4">
      <div>
        <div
          class="
            h-10
            w-10
            rounded-full
            bg-bg-alternate-14
            justify-center
            items-center
            flex
          "
        >
          <MailIcon />
        </div>
      </div>

      <p class="text-sm text-text-alternate-1 font-medium">
        {{ props.fundraiser.contactEmail }}
      </p>
    </div>

    <div class="flex items-center gap-3 pt-4">
      <div>
        <div
          class="
            h-10
            w-10
            rounded-full
            bg-bg-alternate-14
            justify-center
            items-center
            flex
          "
        >
          <PhoneIcon />
        </div>
      </div>

      <p class="text-sm text-text-alternate-1 font-medium">
        {{ props.fundraiser.contactPhoneNumber }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  fundraiser: {
    type: Object,
  },
});
</script>
